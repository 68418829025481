<template>
	<layout-default>
		<div>Loading.......</div>
	</layout-default>
</template>
<script>
import storeShared from '@/_srcv2/pages/_pages-store/storeShared.js'
import storeAccounting from '@/_srcv2/pages/accounting/accounting-store/storeAccounting.js'
import GetCollectedPaymentsReportDataQuery from '../graphql/query/getCollectedPaymentsReportData.query.gql'
import { useQuery } from '@vue/apollo-composable'
import { onMounted, reactive } from '@vue/composition-api'
import Store from '@/store'
import Router from '@/router'

export default {
	name: 'GetCollectedPaymentsReport',
	setup() {
		// get state from composable
		// const ourCompany = ref(reportHeaders.ourCompany)
		// const reportNumber = ref(reportHeaders.reportNumber)
		const {
			getNewDateWithTime,
			numberFromDataBaseToTable,
			roundedCurrencyAddedNumber,
			getTransactionType,
		} = storeShared()
		const { reportHeaders, reportData } = storeAccounting()
		console.log('GetCollectPaymentsReport reportHeaders', reportHeaders)
		// const isConditionOk =
		//   reportHeaders.ourCompany.length !== 0 && reportHeaders.reportNumber !== 0
		// const setInitialState = (condition) => {
		//   return new Promise((resolve, reject) => {
		//     if (condition) {
		//       // ourCompany.value = reportHeaders.ourCompany
		//       // reportNumber.value = reportHeaders.reportNumber
		//       resolve('ok')
		//     } else {
		//       const reason = new Error('Data could not be fetched from database')
		//       reject(reason)
		//     }
		//   })
		// }
		onMounted(() => {
			console.log('----mounted----')
			// setInitialState(isConditionOk).then(() => getReportData())
			getReportData()
		})
		// Get report data from db
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { refetch } = useQuery(
			GetCollectedPaymentsReportDataQuery,
			() => ({
				our_company: reportHeaders.ourCompany,
				report_number: reportHeaders.reportNumber,
			}),
			options,
		)
		// refetch().then((result) => console.log('result', result))
		// Map the data to consume in the report
		const mapReportData = (qResult) => {
			console.log('qResult------------------------', qResult)
			return new Promise((resolve, reject) => {
				if (qResult !== null || undefined) {
					// map
					const mapSubLines = (array) => {
						return array.map((item) => {
							return {
								account: item.account_id_name,
								credit: item.credit,
								debit: item.debit,
							}
						})
					}
					reportData.tableLinesSubLines =
						qResult.data.collect_payment_report_headers[0].cp_report_headers_bk_headers_rel.map(
							(item) => {
								return {
									date: item.book_keep_date,
									transactionType: getTransactionType(item.transaction_type),
									companyId: item.customer_or_supplier_id,
									companyTitle: item.customer_or_supplier_title,
									documentNumber: item.document_number,
									exchangeRate: item.exchange_rate,
									currency: item.exchange_unit,
									credit: item.payment_credit_amount,
									creditExchange: item.payment_credit_exchange_amount,
									subLines: mapSubLines(
										item.book_keep_headers_book_keep_lines_rel,
									),
								}
							},
						)
					reportData.countTransactions =
						qResult.data.collect_payment_report_headers[0].cp_report_headers_bk_headers_rel_aggregate.aggregate.count
					reportData.creditAmount =
						qResult.data.collect_payment_report_headers[0].cp_report_headers_bk_headers_rel_aggregate.aggregate.sum.payment_credit_amount
					reportData.creditExchangeAmount =
						qResult.data.collect_payment_report_headers[0].cp_report_headers_bk_headers_rel_aggregate.aggregate.sum.payment_credit_exchange_amount
					reportData.coverLines = qResult.data.book_keep_lines.map((item) => {
						return {
							account: item.account_id_name,
							credit:
								item
									.book_keep_lines_book_keep_lines_account_id_name_rel_aggregate
									.aggregate.sum.credit,
							debit:
								item
									.book_keep_lines_book_keep_lines_account_id_name_rel_aggregate
									.aggregate.sum.debit,
						}
					})
					reportData.coverCreditTotals =
						qResult.data.book_keep_lines_aggregate.aggregate.sum.credit
					reportData.coverDebitTotals =
						qResult.data.book_keep_lines_aggregate.aggregate.sum.debit
					resolve({
						tableLinesSubLines: reportData.tableLinesSubLines,
						countTransactions: reportData.countTransactions,
						creditAmount: reportData.creditAmount,
						creditExchangeAmount: reportData.creditExchangeAmount,
						coverLines: reportData.coverLines,
						coverDebitTotals: reportData.coverDebitTotals,
						coverCreditTotals: reportData.coverCreditTotals,
					})
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		// Create docDefinition for pdfMake
		const createDocDefinition = (reportData) => {
			console.log(
				'from the beginning of createDocDefinition reportData',
				reportData,
			)
			return new Promise((resolve, reject) => {
				if (reportData !== null || undefined) {
					const data = {
						headerLeft: reportHeaders.ourCompany, // Store.getters.getCurrentCompanyTitle,
						// headerCenter: 'myCenterHeader',
						headerRight: `Utskrivet: ${getNewDateWithTime()}`,
						footerLeft: reportHeaders.ourCompany, // Store.getters.getCurrentCompanyTitle,
						footerCenter: 'myCenterFooter',
						// footerRight: 'myRightFooter',
						watermark: Store.getters.getWatermark,
						page: 'sida (',
						of: ') av ',
						// Cover ----------------------------------
						coverTitle: reportHeaders.coverTitle,
						accountTag: 'Konto',
						debitTag: 'Debet',
						creditTag: 'Kredit',
						footerTag: 'Omslutning',
						// Report
						invoiceNumberTag: 'Fakturanr.',
						customerNumberNameTag: 'Kundnr. Kundnamn.',
						paymentTypeTag: 'Betalsätt',
						paymentDateTag: 'Betdat',
						amountTag: 'Belopp',
						paymentCurrencyTag: 'Betalkurs',
						paymentInSekTag: 'Belopp kr.',
					}
					const docDefinition = reactive({
						pageSize: 'A4',
						pageOrientation: 'portrait',
						pageMargins: [50, 50, 40, 70],
						header: {
							columns: [
								{ text: data.headerLeft, style: 'documentHeaderLeft' },
								// { text: data.headerCenter, style: "documentHeaderCenter" },
								{ text: data.headerRight, style: 'documentHeaderRight' },
							],
						},
						// footer: {
						//   columns: [
						//     { text: data.footerLeft, style: "documentFooterLeft" },
						//     { text: data.footerCenter, style: "documentFooterLineCenter" },
						//     { text: data.footerCenter, style: "documentFooterRight" }
						//   ]
						// },
						footer: function (currentPage, pageCount) {
							return {
								columns: [
									// {
									//   table: {
									//     widths: ['*'],
									//     body: [[' '], [' ']]
									//   },
									//   style: 'documentFooterLineLeft',
									//   layout: 'lightHorizontalLines'
									// },
									{ text: data.footerLeft, style: 'documentFooterLeft' },
									// {
									//   table: {
									//     widths: ['*'],
									//     body: [[' '], [' ']]
									//   },
									//   style: 'documentFooterLineCenter',
									//   layout: 'lightHorizontalLines'
									// },
									{
										text:
											data.page + currentPage.toString() + data.of + pageCount,
										style: 'documentFooterRight',
									},
								],
							}
						},
						// background: function() {
						//   return {
						//     image: data.watermark,
						//     opacity: 0.3,
						//     alignment: 'center',
						//     height: 264,
						//     margin: [0, (842 - 264) / 2, 0, 0]
						//   }
						// },
						background: [
							{
								image: data.watermark,
								opacity: 0.3,
								alignment: 'center',
								height: 264,
								margin: [0, (842 - 264) / 2, 0, 0],
							},
							{
								canvas: [
									{
										type: 'line',
										x1: 50,
										y1: 195,
										x2: 570,
										y2: 195,
										lineWidth: 1,
									},
								],
							},
						],
						content: [],
						styles: {
							// Document Header
							documentHeaderLeft: {
								fontSize: 10,
								margin: [50, 20, 5, 20],
								alignment: 'left',
							},
							documentHeaderCenter: {
								fontSize: 10,
								margin: [5, 5, 5, 40],
								alignment: 'center',
							},
							documentHeaderRight: {
								fontSize: 10,
								margin: [0, 20, 40, 20],
								alignment: 'right',
							},
							// Document Footer
							documentFooterLeft: {
								fontSize: 12,
								bold: true,
								margin: [50, 30, 5, 40],
								alignment: 'left',
							},
							documentFooterLineLeft: {
								fontSize: 10,
								margin: [50, 0, 5, 0],
								alignment: 'left',
							},
							documentFooterLineCenter: {
								fontSize: 10,
								margin: [0, 0, 5, 0],
								alignment: 'center',
							},
							documentFooterLineRight: {
								fontSize: 10,
								margin: [0, 0, 5, 50],
								alignment: 'right',
							},
							documentFooterRight: {
								fontSize: 10,
								margin: [5, 30, 40, 40],
								alignment: 'right',
							},
							// Document Title
							documentTitle: {
								fontSize: 22,
								bold: true,
								alignment: 'right',
								margin: [0, 0, 0, 15],
							},
							// Document Details
							documentSubTitle: {
								fontSize: 12,
								alignment: 'right',
							},
							documentSubValue: {
								fontSize: 12,
								alignment: 'right',
							},
							// Items Footer (Subtotal, Total, Tax, etc)
							itemsFooterSubTitle: {
								margin: [0, 5, 0, 0],
								bold: true,
								alignment: 'right',
								fontSize: 10,
							},
							itemsFooterSubValue: {
								margin: [0, 5, 13, 0],
								bold: true,
								alignment: 'right', // center
								fontSize: 10,
							},
							itemsFooterTotalTitle: {
								margin: [0, 3, 0, 5],
								bold: true,
								alignment: 'right',
								fontSize: 10,
							},
							itemsFooterTotalValue: {
								margin: [0, 3, 12, 5], // *******************
								bold: true,
								alignment: 'right',
								fontSize: 10,
							},
							center: {
								alignment: 'center',
							},
							itemVat: {
								margin: [2, 5, 2, 5],
								alignment: 'right',
								fontSize: 10,
							},
							itemVatAmount: {
								margin: [0, 5, 14, 5],
								alignment: 'right',
								fontSize: 10,
							},
							paymentTableStyle: {
								margin: [0, 10, 0, 0],
							},
						},
						tableHeader: {
							bold: true,
							alignment: 'center',
							fontSize: 10,
						},
						defaultStyle: {
							columnGap: 20,
						},
						// Cover
						coverTitle: {
							fontSize: 18,
							bold: true,
							alignment: 'justify',
						},
						textInTable: {
							alignment: 'left',
						},
						numberInTable: {
							alignment: 'justify',
						},
						// Report
						reportTableHeader: {
							bold: true,
							alignment: 'left',
							fontSize: 10,
						},
					})
					// ********************** PUSH CONTENT BEGIN********************************************************
					// Cover Headers
					const coverHeader = {
						text: data.coverTitle,
						style: 'coverTitle',
						alignment: 'center',
						margins: [0, 0, 0, 40],
					}
					const coverSubHeaders = {
						columns: [
							{
								text: `Verifikationstext: ${reportHeaders.reportType} ${reportHeaders.reportNumber}`,
								alignment: 'left',
							},
							{
								text: `Verifikationsdatum: ${reportHeaders.reportDate}`,
								alignment: 'right',
							},
						],
						margin: [0, 20, 0, 0],
					}
					const horizontalLine = {
						table: {
							widths: ['*'],
							body: [[' '], [' ']],
						},
						layout: 'lightHorizontalLines',
					}
					// Cover Table - Lines - table footer
					let coverTableHeaderRow = []
					const pushCoverTableHeaderRow = () => {
						coverTableHeaderRow.push([
							{
								text: data.accountTag,
								style: ['textInTable', 'center'],
							},
							{
								text: data.debitTag,
								style: ['textInTable', 'center'],
							},
							{
								text: data.creditTag,
								style: ['textInTable', 'center'],
							},
						])
					}
					pushCoverTableHeaderRow()
					const pushCoverTableHeader = () => {
						docDefinition.content.push({
							table: {
								// headers are automatically repeated if the table spans over multiple pages
								// you can declare how many rows should be treated as headers
								headerRows: 1,
								widths: ['*', 80, 80],
								style: 'tableHeader',
								body: coverTableHeaderRow,
							},
							layout: 'noBorders',
						})
					}
					let coverTableRows = []
					// push values to cover table ro coverTableRows array
					const pushRowsItemsLines = (coverTableLines) => {
						for (let item of coverTableLines) {
							coverTableRows.push([
								{
									text: item.account,
									alignment: 'left',
								},
								{
									text: numberFromDataBaseToTable(item.debit),
									alignment: 'right',
								},
								{
									text: numberFromDataBaseToTable(item.credit),
									alignment: 'right',
								},
							])
						}
					}
					pushRowsItemsLines(reportData.coverLines)
					console.log('after lines coverTableRows', coverTableRows)
					// push cover table
					const pushCoverTableLines = () => {
						docDefinition.content.push({
							table: {
								// headers are automatically repeated if the table spans over multiple pages
								// you can declare how many rows should be treated as headers
								headerRows: 1,
								widths: ['*', 80, 80],
								style: 'tableHeader',
								body: coverTableRows,
							},
							layout: 'noBorders',
						})
					}
					//
					let coverTableFooterRow = []
					const pushCoverTableFooterRow = () => {
						coverTableFooterRow.push([
							{
								text: data.footerTag,
								alignment: 'left',
							},
							{
								text: numberFromDataBaseToTable(reportData.coverDebitTotals),
								alignment: 'right',
							},
							{
								text: numberFromDataBaseToTable(reportData.coverCreditTotals),
								alignment: 'right',
							},
						])
					}
					pushCoverTableFooterRow()
					// push cover table
					const pushCoverTableFooter = () => {
						docDefinition.content.push({
							table: {
								// headers are automatically repeated if the table spans over multiple pages
								// you can declare how many rows should be treated as headers
								headerRows: 1,
								widths: ['*', 80, 80],
								style: 'tableHeader',
								body: coverTableFooterRow,
							},
							layout: 'noBorders',
							pageBreak: 'after',
						})
					}
					// Collected Payment Report
					// Report Headers
					const reportHeader = {
						text: `${reportHeaders.reportType} ${reportHeaders.reportNumber}`,
						style: 'coverTitle',
						alignment: 'center',
						margins: [0, 0, 0, 40],
					}
					const reportSubHeaders = {
						columns: [
							{
								text: '',
								alignment: 'left',
							},
							{
								text: `Journaldatum: ${reportHeaders.reportDate}`,
								alignment: 'right',
							},
						],
						margin: [0, 20, 0, 0],
					}
					// Report Table - Lines - table footer
					let reportTableHeaderFirstRow = []
					const pushReportTableHeaderFirstRow = () => {
						reportTableHeaderFirstRow.push([
							{
								text: data.invoiceNumberTag,
								style: ['textInTable', 'left'],
							},
							{
								text: data.customerNumberNameTag,
								style: ['textInTable', 'left'],
							},
							{
								text: '',
								style: ['textInTable', 'left'],
							},
						])
					}
					pushReportTableHeaderFirstRow()
					const pushReportTableHeaderFirst = () => {
						docDefinition.content.push({
							table: {
								// headers are automatically repeated if the table spans over multiple pages
								// you can declare how many rows should be treated as headers
								headerRows: 1,
								widths: [60, 150, '*'],
								style: 'reportTableHeader',
								body: reportTableHeaderFirstRow,
							},
							layout: 'noBorders',
						})
					}
					let reportTableHeaderSecondRow = []
					const pushReportTableHeaderSecondRow = () => {
						reportTableHeaderSecondRow.push([
							{
								text: '',
								style: ['textInTable', 'center'],
							},
							{
								text: data.paymentTypeTag,
								style: ['textInTable', 'center'],
							},
							{
								text: data.paymentDateTag,
								style: ['textInTable', 'center'],
							},
							{
								text: data.amountTag,
								style: ['textInTable', 'center'],
							},
							{
								text: data.paymentCurrencyTag,
								style: ['textInTable', 'center'],
							},
							{
								text: data.paymentInSekTag,
								style: ['textInTable', 'center'],
							},
						])
					}
					pushReportTableHeaderSecondRow()
					const pushFirstRow = (item) => {
						// console.log('from pushFirstRow-----', item)
						docDefinition.content.push({
							table: {
								// headers are automatically repeated if the table spans over multiple pages
								// you can declare how many rows should be treated as headers
								headerRows: 0,
								widths: [60, 450, '*'],
								style: 'tableHeader',
								body: [
									[
										{
											text: item.documentNumber,
										},
										{
											text: item.companyId + ' ' + item.companyTitle,
										},
									],
								],
							},
							layout: 'noBorders',
						})
					}
					const pushReportTableHeaderSecond = () => {
						docDefinition.content.push({
							table: {
								// headers are automatically repeated if the table spans over multiple pages
								// you can declare how many rows should be treated as headers
								headerRows: 1,
								widths: [80, 80, 80, '*', 80, 80],
								style: 'tableHeader',
								body: reportTableHeaderSecondRow,
							},
							layout: 'noBorders',
						})
					}
					const pushSecondRow = (item) => {
						// console.log('from pushSecondRow-----', item)
						docDefinition.content.push({
							table: {
								// headers are automatically repeated if the table spans over multiple pages
								// you can declare how many rows should be treated as headers
								headerRows: 0,
								widths: [80, 80, 80, '*', 80, 80],
								style: 'tableHeader',
								body: [
									[
										{
											text: '',
										},
										{
											text: item.transactionType,
										},
										{
											text: item.date,
										},
										{
											text: roundedCurrencyAddedNumber(
												item.creditExchange,
												item.currency,
											),
											alignment: 'right',
										},
										{
											text: item.exchangeRate.toFixed(4),
											alignment: 'center',
										},
										{
											text: numberFromDataBaseToTable(item.credit),
											alignment: 'right',
										},
									],
								],
							},
							layout: 'noBorders',
						})
						for (let subLine of item.subLines) {
							pushSubLine(subLine)
						}
					}
					const pushSubLine = (subLine) => {
						// console.log('-------------subLine', subLine)
						docDefinition.content.push({
							table: {
								headerRows: 0,
								widths: [80, '*', 80, 80],
								style: 'tableHeader',
								body: [
									[
										{
											text: '',
											alignment: 'left',
										},
										{
											text: subLine.account,
											alignment: 'left',
										},
										{
											text: numberFromDataBaseToTable(subLine.debit),
											alignment: 'right',
										},
										{
											text: numberFromDataBaseToTable(subLine.credit),
											alignment: 'right',
										},
									],
								],
							},
							layout: 'noBorders',
						})
					}
					const pushReportTable = () => {
						for (let item of reportData.tableLinesSubLines) {
							pushFirstRow(item)
							pushSecondRow(item)
							docDefinition.content.push(horizontalLine)
							// pushSubTable(item)
						}
					}
					const reportFooter = {
						alignment: 'justify',
						columns: [
							{
								text: 'Antal transaktioner: ',
								alignment: 'left',
							},
							{
								text: reportData.countTransactions,
								alignment: 'right',
							},
							{
								text: 'Summa betalt: ',
								alignment: 'left',
							},
							{
								text: numberFromDataBaseToTable(reportData.creditAmount),
								alignment: 'right',
							},
						],
					}
					// push
					docDefinition.content.push(coverHeader)
					docDefinition.content.push(coverSubHeaders)
					docDefinition.content.push(horizontalLine)
					pushCoverTableHeader()
					docDefinition.content.push(horizontalLine)
					pushCoverTableLines()
					docDefinition.content.push(horizontalLine)
					pushCoverTableFooter()
					docDefinition.content.push(reportHeader)
					docDefinition.content.push(reportSubHeaders)
					docDefinition.content.push(horizontalLine)
					pushReportTableHeaderFirst()
					pushReportTableHeaderSecond()
					docDefinition.content.push(horizontalLine)
					pushReportTable()
					docDefinition.content.push(reportFooter)
					resolve(docDefinition)
				} else {
					const reason = new Error('docDefinition could not be created')
					reject(reason)
				}
			})
		}
		// Store the docDefinition
		const storeDocDefinition = (result) => {
			return new Promise((resolve, reject) => {
				if (result !== null || undefined) {
					reportData.docDefinition = result
					resolve('ok')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		// Execute the functions to store the report data to the state
		const getReportData = () => {
			refetch().then((result) => {
				mapReportData(result)
					.then((result) => createDocDefinition(result))
					.then((result) => storeDocDefinition(result))
					.then(() => Router.push({ name: 'CollectedPaymentsReport' }))
				console.log('******/** reportData', reportData)
				console.log('from GetCollectedPaymentsReport', reportHeaders)
			})
		}
		return {
			getReportData,
		}
	},
}
</script>
<style scoped></style>
