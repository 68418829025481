// To share the state between components write thw following three lines
// Otherwise each component creates a new instance of the reactive state object that can not sher the state
import Vue from 'vue'
import VueCompositionApi, {
	getCurrentInstance,
	reactive,
} from '@vue/composition-api'
Vue.use(VueCompositionApi)

const getPaymentAbbreviation = (item) => {
	switch (item) {
		case 'collect bank':
			return 'BT'
		case 'collect bank in advance':
			return 'BT'
		case 'collect bank reminder fee':
			return 'BT'
		case 'collect cash':
			return 'CT'
		case 'collect cash in advance':
			return 'CT'
		case 'collect cash reminder fee':
			return 'CT'
		case 'set off advanced payment':
			return 'SO'
		case 'set off credit sale-invoice':
			return 'SO'
		case 'set off':
			return 'SO'
		default:
			return '??'
	}
}
// Create uuid for address-id
const vm = getCurrentInstance()
const NAMESPACE = '96e1b132-5765-5e15-b9a8-8a6cec456dda'

function getUUID() {
	let TIMEPARAMETER = (
		Date.now() + Math.floor(Math.random() * 1000000000000000000).toString()
	).toString()
	return vm.$uuid.v5(TIMEPARAMETER, NAMESPACE)
}

const reportData = reactive({
	beginningDate: new Date(),
	endingDate: new Date(),
	tableLinesSubLines: [],
	countTransactions: 0,
	creditAmount: 0,
	creditExchangeAmount: 0,
	coverLines: [],
	coverDebitTotals: 0,
	coverCreditTotals: 0,
	docDefinition: [],
})
const reportHeaders = reactive({
	reportDate: '2021-12-07',
	reportType: 'Inbetalningsjournal nr. ',
	reportNumber: 184,
	ourCompany: 'alfe',
	coverTitle: 'Bokföringsunderlag',
})
export default function storeAccounting() {
	return {
		getPaymentAbbreviation,
		getUUID,
		reportData,
		reportHeaders,
	}
}
